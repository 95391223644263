import DashboardLayout from "~theme/DashboardLayout.vue";

const routes = [
	{
		path: "/",
		component: DashboardLayout,
		redirect: "/dashboard",
		children: [
			{
				path: "/abbonamenti",
				name: "Sportello Abbonamenti",
				meta: { authorize: ["admin", "sportello"] },
				// lazy-loaded
				component: pageComponent("index"),
			},
			{
				path: "/abbonamenti/riepilogo",
				name: "Le tue Richieste",
				meta: { authorize: ["admin", "sportello"] },
				// lazy-loaded
				component: pageComponent("riepilogo"),
			},
			{
				path: "/abbonamenti/gtt",
				name: "Sportello Abbonamenti GTT",

				meta: {},
				// lazy-loaded
				component: pageComponent("gtt"),
			},
			{
				path: "/abbonamenti/trenord",
				name: "Sportello Abbonamenti Trenord",

				meta: {},
				// lazy-loaded
				component: pageComponent("trenord"),
			},
			{
				path: "/abbonamenti/atm",
				name: "Accesso Sportello ATM",

				meta: {},
				// lazy-loaded
				component: pageComponent("atm"),
			},
			{
				path: "/abbonamenti/atac",
				name: "Accesso Sportello ATAC",

				meta: {},
				// lazy-loaded
				component: pageComponent("atac"),
			},
			{
				path: "/abbonamenti/trenord/login",
				name: "Accesso Login Trenord",

				meta: {},
				// lazy-loaded
				component: pageComponent("trenord-login"),
			},         	
        	],
	},
	//Questi elementi usano il template di default
	{
		path: "/abbonamenti/jointly",
		name: "Single Sign On Jointly",

		meta: {},
		// lazy-loaded
		component: pageComponent("jointly"),
	},
	{
		path: "/abbonamenti/ah",
		name: "Single Sign On Airport Handling",

		meta: {},
		// lazy-loaded
		component: pageComponent("ah"),
	},
];

/**
 * Asynchronously load view (Webpack Lazy loading compatible)
 * The specified component must be inside the Views folder
 * @param  {string} name  the filename (basename) of the view to load.
 * **/
function pageComponent(name) {	
	var res;	
	try {
		res = require("@/extra/Abbonamenti/pages/" + name + ".vue").default;
	} catch (e) {
		//console.log("Errore route");
		res = require("./pages/" + name + ".vue").default;
	}	
	return res;
}

export default routes;